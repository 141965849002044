import React, {useState} from 'react';
import { useFormik } from 'formik';
import { Redirect } from "react-router-dom";

import { useHistory } from "react-router-dom";
import useAuth from "../obj/auth/hooks/useAuth";

//import useHistory from '../obj/npm auth/hooks/useHistory';
//import useAuthActions from "../obj/auth/hooks/useAuthActions";
//import useAuth from "../obj/auth/hooks/useAuth";

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'این فیلد الزامی است';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'لطفا یک ایمیل معتبر وارد کنید';
    }

    return errors;
};

function SignupForm(){
    const[response_res,setresponse_res] = useState(false);
    const[api_err,setapi_err] = useState(false);
    const[send_res,setsend_res] = useState(false);
    const[buttonstatus,setbuttonstatus] = useState(false);

    let history = useHistory();

    const loggedIn = useAuth();
    if(loggedIn===true){
        history.push("/profile");
    }



    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validate,
        onSubmit: values => {
            setsend_res(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' },
                body: JSON.stringify(values)
            };
            //console.log(requestOptions);
            fetch('https://test.quiz360.ir/public/api/login/login1', requestOptions)
                .then(response => response.json())
                .then(data => auth(data) );
            ////console.log(resp?.data.api_token)

            const auth = data => {
                console.log(data.data.data[0]);
                if(data.data.data[0]===0){

                    setresponse_res(true);
                    setbuttonstatus(true);
                    setsend_res(false);
                }
                else if (data.data.data[0]===2||data.data.data[0]===1){
                        console.log('done');
                    history.push("/login2/"+values.email)
                }

                else{
                    setsend_res(false);
                    setapi_err(true);
                    return true;
                }
            }
        },
    });
    return (
        <form style={{textAlign:'center',marginTop:'20px'}} className="text-center" onSubmit={formik.handleSubmit}>
            <div className="form-row">
            <div  className="form-group col-md-4 mx-auto">
            <label htmlFor="email">آدرس ایمیل</label>
            <input
                id="email"
                name="email"
                type="email"
                class="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
            />
                <div className="alert-danger border-danger">
            {formik.touched.email && formik.errors.email ? (
                <div>{formik.errors.email}</div>
            ) : null}</div>
            </div>
            </div>


                {response_res ? (
                    <div className="alert-success col-md-4 mx-auto">ایمیل شما وجود ندارد ایمیل فعالسازی ارسال شد</div>
                ):''}

            {api_err ? (<div className="alert-warning col-md-4 mx-auto">مشکلی در ارتباط روی داده</div>):''}


            {!send_res ? (<div>
                    {!buttonstatus ?
                    (<button type="submit" className="btn form-group btn-secondary">تایید</button>):
                    ('')
            }
            </div>)
                :
                (<button className="btn btn-secondary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
                    <span className="sr-only">در حال ارسال ...</span>
                </button>)
            }


        </form>
    );
};

export default SignupForm