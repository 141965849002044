import React, {useState} from 'react';
import { useFormik } from 'formik';
import { Redirect } from "react-router-dom";

import { useHistory , useParams } from "react-router-dom";

//import useHistory from '../obj/npm auth/hooks/useHistory';
import useAuthActions from "../obj/auth/hooks/useAuthActions";
import useAuth from "../obj/auth/hooks/useAuth";

const validate = values => {
    const errors = {};

    if (!values.code) {
        errors.code = 'این فیلد الزامی است';
    }

    return errors;
};

function Login2(){
    const[response_res,setresponse_res] = useState(false);
    const[api_err,setapi_err] = useState(false);
    const[send_res,setsend_res] = useState(false);
    const[buttonstatus,setbuttonstatus] = useState(false);

    let history = useHistory();

    const setLogin = useAuthActions();

    const loggedIn = useAuth();
    if(loggedIn===true){
        history.push("/profile");
    }

    const {mail} = useParams();

    console.log(mail);

    //const history = useHistory();

    const formik = useFormik({
        initialValues: {
            code:''
        },
        validate,
        onSubmit: values => {
            setsend_res(true);

            const send_data = {'code':values.code,'email':mail}

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' },
                body: JSON.stringify(send_data)
            };
            //console.log(requestOptions);
            fetch('https://test.quiz360.ir/public/api/login/login2', requestOptions)
                .then(response => response.json())
                .then(data => auth(data) );
            ////console.log(resp?.data.api_token)

            const auth = data => {

                if(typeof data?.data?.data != "undefined"){

                    if(data.data.data[0]===0){
                        setresponse_res(true);
                        setsend_res(false);
                    }
                }


                else if (data?.data?.status===1){
                    setLogin(true)

                    localStorage.setItem('api_token', data.data.api_token);
                    localStorage.setItem('email', data.data.email);
                    history.push("/profile");
                }

                else{
                    setsend_res(false);
                    setapi_err(true);
                    return true;
                }
            }
        },
    });
    return (
        <form style={{textAlign:'center',marginTop:'20px'}} className="text-center" onSubmit={formik.handleSubmit}>


            <div  className="form-group col-md-4 mx-auto">

                <label htmlFor="code">کد تایید</label>
                <input
                    id="code"
                    name="code"
                    type="code"
                    class="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.code}
                />
                <div className="alert-danger border-danger">
                    {formik.touched.code && formik.errors.code ? (
                        <div>{formik.errors.code}</div>
                    ) : null}</div>


            </div>


            {response_res ? (
                <div className="alert-danger col-md-4 mx-auto">کد وارد شده اشتباه است</div>
            ):''}

            {api_err ? (<div className="alert-warning col-md-4 mx-auto">مشکلی در ارتباط روی داده</div>):''}


            {!send_res ? (<div>
                    {!buttonstatus ?
                        (<button type="submit" className="btn form-group btn-secondary">تایید</button>):
                        ('')
                    }
                </div>)
                :
                (<button className="btn btn-secondary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
                    <span className="sr-only">در حال ارسال ...</span>
                </button>)
            }


        </form>
    );
};

export default Login2