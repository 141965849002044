import React from 'react';
import FigureImage from 'react-bootstrap/FigureImage'
import Figure from 'react-bootstrap/Figure';
import Badge from 'react-bootstrap/Badge';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container'


function Pagemore(props){



    return(
        <div className="text-center" >

          <h1>
            <Badge variant="secondary">{props.title}</Badge>
          </h1>

        <Figure>
            <Figure.Image
              width={800}
              height={800}
              alt="سرعت بارگیری تصویر پایین است ..."
              src={props.src}
            />
            <Figure.Caption>
              {props.id1}
            </Figure.Caption>
        </Figure>

        <Jumbotron fluid>
                <Container>
                  <p>
                    {props.id2}
                  </p>
                </Container>
        </Jumbotron>
            <p>{props.id3}</p>
            
        </div>

    )
}

Pagemore.defaultProps = {

    title:"Sample Title",
    id1:"مقداری وجود ندارد",
    id2:"مقداری وجود ندارد",
    id3:"مقداری وجود ندارد",
    id4:"مقداری وجود ندارد",
    id5:"مقداری وجود ندارد",
    id12:"مقداری وجود ندارد",
    src:"https://homepages.cae.wisc.edu/~ece533/images/boat.png",
    text:"Sample Text",
    click:"1",
    func:"بیشتر"
}

export default Pagemore;