import React,{useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import List from '../obj/list/pagation/listpagation';
import axios from 'axios';


function Scrollerinf(){

    const[loading,setloading] = useState(true);
    const[loading2,setloading2] = useState(false);
    const[done,setdone] = useState(false);
    const[hasMore,sethasMore] = useState(true);
    const[Resv,setResv] = useState([]);
    const[nextpage,setnextpage] = useState(1);
    const[nextpage2,setnextpage2] = useState(0);


    const loadmore = (e)=>{

        //console.log(e)

        const email = localStorage.getItem('email');
        const api_token = localStorage.getItem('api_token');
        const datasend = {'email':email,'api_token':api_token};

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
            body: JSON.stringify(datasend)
        };
        //console.log(requestOptions);
        fetch('https://test.quiz360.ir/public/api/product/product_auth_paginate?page='+nextpage, requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );
        ////console.log(resp?.data.api_token)

        const setdataapi = data1 => {

            //console.log(data1)
            if(nextpage===data1.meta.last_page){
                sethasMore(false)
            }

            setResv([...Resv,...data1.data]);
            setloading2(true)
            setnextpage(nextpage+1);
            setloading(true)




            console.log(Resv)


        }


    }

    return(

        <div style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}  >
            <InfiniteScroll
                className="row-cols-3"
                pageStart={1}
                loadMore={loadmore}
                hasMore={hasMore}
                loader={
                    <div><br></br><div class="spinner-border"  role="status">
                        <span class="sr-only">Loading...</span>
                    </div></div>
                }
            >

                <div  >
                    {Resv.map((item,index)=>{return <List

                        src={item.image}
                        title={item.title}
                        id1={item.description.substring(0,75)+"..."}
                        id2={" تومان "+item.price}
                        id5={"/more/"+item.id}
                        key={index}
                    />;})}
                </div>

            </InfiniteScroll>


        </div>

    );
}

export default Scrollerinf;