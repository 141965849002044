import React from 'react';
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../obj/auth/components/PrivateRoute";


//pages

import NotFound from '../pages/NotFound';
import LogoutPage from '../obj/auth/pages/LogoutPage.js'
import SignupForm from '../pages/mainlogin.jsx';
import Login2 from '../pages/login2.jsx';
import Veryfiyemail from '../pages/veryfiyemail';
import Profile from '../pages/profle.js';
import Pagation_result from '../pages/pagation';
import Scrollerinf from '../pages/scrollerinf';
import More from '../pages/product_info';
import ToDo_Page from '../pages/todo';




function Route_file() {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={SignupForm} />
                <Route path="/login" component={SignupForm}/>
                <Route path="/login2/:mail" component={Login2}/>
                <Route path="/variyfiymail/:mail/:id" component={Veryfiyemail}/>
                <Route path="/todo" component={ToDo_Page}/>




                <PrivateRoute path="/logout" component={LogoutPage} />
                <PrivateRoute path="/profile" component={Profile} />
                <PrivateRoute path="/pagation" component={Pagation_result} />
                <PrivateRoute path="/scrollerinf" component={Scrollerinf} />
                <PrivateRoute path="/more/:id" component={More}/>



                <Route path="*" component={NotFound} />
            </Switch>

        </div>
    );
}

export default Route_file;
