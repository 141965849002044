import React from 'react';
import AuthProvider from "./obj/auth/components/AuthProvider";
import Route_file from "./route/route";
import Header from "./obj/template/header";
import Footer from "./obj/template/footer";
import './styles/main.css';
import './styles/bootstrap.min.css';
import  './styles/bootstrap-grid.css';

function App() {
  return (
    <div className="container-fluid ">
      <AuthProvider>
          <Header />
        
        <div className='main-body'>
        <Route_file />
        </div>

          <Footer />
      </AuthProvider>
    </div>
  );
}

export default App;
