import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';

import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'


function Profile() {

    return(
        <div>
            <Jumbotron fluid>
                <Container>
                    <div className="text-center">
                        <Link className="btn btn-success" to="pagation">صفحه بندی</Link>
                        <br/>
                        <br/>
                        <Link className="btn btn-success" to="scrollerinf">اسکولر بینهایت</Link>
                    </div>
                </Container>
            </Jumbotron>

        </div>
    );
}

export default Profile;