import React,{useState,useEffect} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import List from "../obj/list/pagation/listpagation";
import Pagemore from '../obj/list/more/page';

function More() {

    const[data,setdata]=useState();
    const[loading,setloading]=useState(false);
    const[dataset,setdataset]=useState(false);
    const {id} = useParams();

    useEffect(() => {

        //console.log("currentPage: "+currentPage)
        const email = localStorage.getItem('email');
        const api_token = localStorage.getItem('api_token');
        const datasend = {'email':email,'api_token':api_token,'id':id};

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
            body: JSON.stringify(datasend)
        };
        //console.log(requestOptions);
        fetch('https://test.quiz360.ir/public/api/product/product_with_id', requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );


        const setdataapi = data1 => {
            setloading(true);

            console.log(data1)
            console.log(data1.data)
            

            if(data1.status === 1){
                setdata(data1.data);
                setdataset(true);
            }else {

            }

        }

    }, []);

    return (
        <div>
        {!loading ? (
            <div className="d-flex justify-content-center">
                <div className="spinner-border text-success" style={{width: '10rem', height: '10rem'}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            ):(
                <div>
                    {dataset ? (
                        <div>
                            {data?.map((item,index)=>{return <Pagemore 
                            title={item.title}
                            id1={item.author+" :نویسنده"}
                            src={item.image}
                            id2={item.description}
                            id3={item.price+" تومان"}
                            />})}
                        </div>
                    ):(
                        <div className="alert alert-warning text-center">
                            خطای اتصال به شبکه
                            <br/>
                            <Link className="btn btn-info" to="/profile">پروفایل</Link>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default More;
