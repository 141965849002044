import React from "react"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from "reactstrap"
import { NavLink } from "react-router-dom"

import useAuth from "../auth/hooks/useAuth"

function Header(props) {
    const [open, setOpen] = React.useState(false)
    const isLoggedIn = useAuth()
    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand href="/">سایت جدید</NavbarBrand>
            <NavbarToggler onClick={() => setOpen((o) => !o)} />
            <Collapse isOpen={open} navbar>
                <Nav className="ml-auto" navbar>

                    <NavItem>
                        <NavLink
                            to="/todo"
                            className="nav-link"
                            activeClassName="active">
                            todo
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            exact
                            to={isLoggedIn ? "/profile" : "/login"}
                            className="nav-link"
                            activeClassName="active"
                        >
                            {isLoggedIn ? "خوش آمدید" : "ورود به حساب کاربری"}
                        </NavLink>
                    </NavItem>
                    {isLoggedIn && (
                        <NavItem>
                            <NavLink
                                exact
                                to="/logout"
                                className="nav-link"
                                activeClassName="active"
                            >
                                خروج
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            </Collapse>
        </Navbar>
    )
}

export default Header