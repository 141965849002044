import React from "react"

function NotFound(props) {
  return (
    <p className="text-center w-100 pt-5 mt-5">
      404
      <br />
      صفحه‌ایی که به دنبال آن بودید پیدا نشد!
    </p>
  )
}

export default NotFound
