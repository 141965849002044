import React from 'react';
import {ListGroup,ListGroupItem} from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';


function List(props){



    return(
        <div >
            <CardGroup>
            <CardDeck>
            <div className="cols-3" >

                <Card style={{ width: '18rem' }}>
                    <Card.Img variant="top" src={props.src} />
                    <Card.Body>
                        <Card.Title>{props.title}</Card.Title>
                        <Card.Text>
                            {props.id1}
                        </Card.Text>
                        <ListGroup className="list-group-flush">
                            <ListGroupItem>{props.id2}</ListGroupItem>
                        </ListGroup>
                        <Link to={props.id5} className="btn btn-secondary" >مشاهده</Link>
                    </Card.Body>
                </Card>




            </div>
            </CardDeck>
            </CardGroup>
        </div>

    )
}

List.defaultProps = {

    title:"Sample Title",
    id1:"مقداری وجود ندارد",
    id2:"مقداری وجود ندارد",
    id3:"مقداری وجود ندارد",
    id4:"مقداری وجود ندارد",
    id5:"مقداری وجود ندارد",
    id12:"مقداری وجود ندارد",
    src:"https://homepages.cae.wisc.edu/~ece533/images/boat.png",
    text:"Sample Text",
    click:"1",
    func:"بیشتر"
}

export default List;