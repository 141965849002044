import React,{useState,useEffect} from 'react';
import Paginator from 'react-hooks-paginator';
import List from '../obj/list/pagation/listpagation';




function Pagation_result(){

    const [loding,setloding] = useState(false);
    const [totalRecords,settotalRecords] = useState(0);
    const [ajson,setajson] = useState()

    const pageLimit = 8;
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [currentData, setCurrentData] = useState([]);




    useEffect(() => {

        //console.log("currentPage: "+currentPage)
        const email = localStorage.getItem('email');
        const api_token = localStorage.getItem('api_token');
        const datasend = {'email':email,'api_token':api_token};

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
            body: JSON.stringify(datasend)
        };
        //console.log(requestOptions);
        fetch('https://test.quiz360.ir/public/api/product/product_auth', requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );
        //console.log(resp?.data.api_token)

        const setdataapi = data1 => {
            console.log(data1)
            setloding(true)
            settotalRecords(data1?.totalcount)

            setData(data1.data)




        }

    }, []);

    useEffect(() => {
        setCurrentData(data?.slice(offset, offset + pageLimit));
    }, [offset, data]);



    //console.log("ajson: "+ajson);

//JSON.parse(item.ajson).map((item1,index1)=>{return item1.numberOfQuestions;}))}  key={index} />;}
//totalPoints  correctPoints private
    return (
        <div>
            {!loding ?(
                <div className="d-flex justify-content-center">
                    <div className="spinner-border text-success" style={{width: '10rem', height: '10rem'}} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            ):(<div>
                <div className="row" style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}>

                    {currentData?.map((item,index)=>{return <List
                        src={item.image}
                        title={item.title}
                        id1={item.description.substring(0,75)+"..."}
                        id2={" تومان "+item.price}
                        id5={"/more/"+item.id}
                        key={index}
                    />;})}



                </div>
                <div>
                    <Paginator
                        totalRecords={totalRecords}
                        pageLimit={8}
                        pageNeighbours={1}
                        setOffset={setOffset}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageNextText="بعدی>>"
                        pagePrevText="<<قبلی"
                    />
                </div>
            </div>)}
        </div>
    );
}

export default Pagation_result;