import React, {useEffect, useState} from "react";
import "../styles/todo.css";

function Todo({ todo, index, completeTodo, removeTodo }) {
    return (
        <div
            className="todo"
            style={{ textDecoration: todo.isCompleted ? "line-through" : "" }}
        >
            {todo.text}

            <div>
                <button onClick={() => completeTodo(index)}>انجام شد</button>
                <button onClick={() => removeTodo(index)}>x</button>
            </div>
        </div>
    );
}

function TodoForm({ addTodo }) {
    const [value, setValue] = useState("");

    const handleSubmit = e => {
        e.preventDefault();
        console.log(!value);
        if (!value) return;
        addTodo(value);
        setValue("");
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                className="input"
                value={value}
                onChange={e => setValue(e.target.value)}
            />
        </form>
    );
}

function ToDo_Page() {
    const [deftodos, setdefTodos] = useState([
        {
            text: "دوچرخه سواری 1 ساعت",
            isCompleted: false
        },
        {
            text: "دراز و نشست 120 عدد",
            isCompleted: false
        },

        {
            text: "طناب 200 عدد",
            isCompleted: false
        }
    ]);

    const [todos, setTodos]= useState([]);

    useEffect(() => {

        //console.log("currentPage: "+currentPage)
        const email = localStorage.getItem('email');
        const api_token = localStorage.getItem('api_token');
        const datasend = {'email':email,'api_token':api_token};

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
            body: JSON.stringify(datasend)
        };
        //console.log(requestOptions);
        fetch('https://test.quiz360.ir/public/api/todo/gettodo', requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );
        //console.log(resp?.data.api_token)

        const setdataapi = data1 => {
            console.log(data1.status)
            if (data1.status===1){
                setTodos(JSON.parse(JSON.parse(data1.data.todo)));
                //setTodos([{"text":"12","isCompleted":false}]);
                console.log(JSON.parse(data1.data.todo))
            }else {
                setTodos(deftodos);
            }

        }

    }, []);

    useEffect(() => {
        const email = localStorage.getItem('email');
        const api_token = localStorage.getItem('api_token');
        const datasend = {'email':email,'api_token':api_token,'todo':todos};

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
            body: JSON.stringify(datasend)
        };


        fetch('https://test.quiz360.ir/public/api/todo/store', requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );

        const setdataapi = data1 => {
            //console.log(data1);

        }

    }, [todos]);

    //console.log(todos);

    const addTodo = text => {
        const newTodos = [...todos, {text ,isCompleted: false} ];
        setTodos(newTodos);
    };

    const completeTodo = index => {
        const newTodos = [...todos];
        newTodos[index].isCompleted = true;
        setTodos(newTodos);
    };

    const removeTodo = index => {
        const newTodos = [...todos];
        newTodos.splice(index, 1);
        setTodos(newTodos);
    };

    return (
        <div className="app">
            <div className="todo-list">
                {todos.map((todo, index) => (
                    <Todo
                        key={index}
                        index={index}
                        todo={todo}
                        completeTodo={completeTodo}
                        removeTodo={removeTodo}
                    />
                ))}
                <TodoForm addTodo={addTodo} />
            </div>
        </div>
    );
}

export default ToDo_Page;